import React from "react"

import Layout from "../components/layout"
import Clients from "../components/clients"
import About from "../components/about"
import SEO from "../components/seo"

import thumbnailPlan from "../../static/images/feature-4.jpg"
import thumbnailBoard from "../../static/images/feature-1.jpg"
import thumbnailNews from "../../static/images/feature-8.jpg"

const IndexPage = () => (
  <Layout lang="pl">
    <SEO title="Outdoor Media Integrator (OMI)" />
    <div id="omi" className={"container"}>
      <div className={"features"}>
        <div className={"feature__item"}>
          <div className={"row"}>
            <div className={"col-6 first"}>
              <div className={"thumbnail"}>
                <img alt={"Event"} src={thumbnailPlan} />
              </div>
            </div>

            <div className={"col-6"}>
              <div className={"feature__content"}>
                <h2>Platforma OMI</h2>
                <ul>
                  <li>
                    Wspiera planowanie, selekcję i optymalizację kampanii OOH
                  </li>
                  <li>
                    Integruje oferty od różnych dostawców udostępniając planerom
                    możliwość planowania reklamy zewnętrznej w jednym miejscu
                  </li>
                  <li>
                    Geotargetowanie nośników pod kątem punktów POI lub własnych
                    punktów Klienta
                  </li>
                  <li>
                    Prezentacja selekcji w postaci map tematycznych, heat map,
                    raportów, tabeli i na wiele innych sposobów
                  </li>
                  <li>Dynamiczna prezentacja kampanii OOH dla Klienta.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className={"feature__item"}>
          <div className={"row"}>
            <div className={"col-6"}>
              <div className={"feature__content"}>
                <h2>Elastyczność</h2>
                <p>
                  Dzięki współpracy z domami mediowymi oraz dostawcami reklamy
                  zewnętrznej Platforma OMI jest stale rozwijana, modyfikowana
                  oraz niejednokrotnie integrowana z rozwiązaniami po stronie
                  użytkownika, czyniąc ją integralnym elementem rozwiązań w
                  zakresie planowania oraz optymalizacji reklamy zewnętrznej OOH
                  jak i DOOH.
                </p>
              </div>
            </div>

            <div className={"col-6 first"}>
              <div className={"thumbnail"}>
                <img alt={"Board"} src={thumbnailBoard} />
              </div>
            </div>
          </div>
        </div>

        <div className={"feature__item"}>
          <div className={"row"}>
            <div className={"col-6 first"}>
              <div className={"thumbnail"}>
                <img alt={"News"} src={thumbnailNews} />
              </div>
            </div>

            <div className={"col-6"}>
              <div className={"feature__content"}>
                <h2>Współpraca i rozwój</h2>
                <p>
                  Współpraca z zewnętrznymi podmiotami, integratorami oraz
                  dostawcami danych umożliwiła, że Platforma Outdoor Media
                  Integrator nie tylko stała się standardem w zakresie narzędzi
                  w pracy media planerów reklamy zewnętrznej ale i daje nowe
                  możliwości przy budowaniu i optymalizacji selekcji reklamy
                  zewnętrznej.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Clients />

    <About />
  </Layout>
)

export default IndexPage
